import React from 'react'
import { throttle } from './../utils/utils';

const defaultContext = {
  data: {
    scrollPosition: 0
  },
  set: () => {}
}

const Context = React.createContext(defaultContext)

class ContextProvider extends React.Component {
  constructor() {
    super()
    this.mounted = false;
    this.setData = this.setData.bind(this);
    this.state = {
      ...defaultContext,
      set: this.setData
    }
  }

  componentDidMount() { 
    this.mounted = true;
    if (this.mounted) {
      window.addEventListener('scroll', throttle(this._onScroll, 75).bind(this));
    }
  } 

  componentWillUnmount() {
    window.removeEventListener('scroll', throttle );
    this.mounted = false;
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData
      }
    }))
  }

  _onScroll() {
    this.setData({ scrollPosition: (window.pageYOffset || document.documentElement.scrollTop) })
  }

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>
  }
}

export default Context
export { ContextProvider }